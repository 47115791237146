












































import { Notify,Dialog,Toast  } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "../../components/ShareComponent/PullDownUp.vue"
import FooterCom from "../../components/ShareComponent/ListBottom.vue"
import Search from "../../components/ShareComponent/Search.vue"
import Stroage from "../../util/Storage"
import { GetSchool,BandSchool,GetUserData } from "../../Api/Basics/index"
import { UserStore } from "../../store/modules/User"
import { SchoolType as SchoolTypes } from "../../Type/index"
import { IsPageNo } from "../../util/filter"
import { IndexStore } from "@/store/modules/Index"
import { Vue,Component,Ref,Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface SchoolType<T=string>{
   userId:T;
   token:T;
   searchVal:T;
   InputShow:boolean;
   loadingShow:boolean;
   PullLoading:boolean;
   UpDown:boolean;
   KeyList:Array<string>;
   data:SchoolTypes;
   UpData:{
       pageNo:number;
       pageSize:number;
       name?:T;
   };
   NotUpDown:boolean;
   SearchBool:boolean;
   BackBool:boolean

   handleShowInput():void;
   handleShowClose():void;
   handleUpSerchBtn():void;

   handleBandSchool(data?:SchoolTypes):void;
   handleUpDown(data:any):void;
   handleUpData(name?:string):void;
   handleFilterList(list:SchoolTypes):void;
   handleError(data:string):void;
}

@Component({ name:"School",components:{ Search,List,FooterCom,HeadTop } })
export default class School extends ZoomPage implements SchoolType{
   userId = "";
   token = "";
   searchVal = "";
   InputShow = false
   loadingShow = true
   PullLoading = false
   UpDown = false
   KeyList: string[] = []
   data: SchoolTypes = {}
   UpData:{
       pageNo:number;
       pageSize:number;
       name?:string;
   } = {
       pageNo:1,
       pageSize:100
   }
   NotUpDown = false
   SearchBool = false
   BackBool = false

    @Ref("serch")
    readonly input!:HTMLElement;

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       this.BackBool = "BackBool" in this.$route.query && /true/.test( this.$route.query.BackBool + "" ) || false;
       if( UserStore.GetSchoolList.length ){
           this.loadingShow = false
           this.UpData.pageNo = IsPageNo(UserStore.GetSchoolList.length,100)
           this.handleFilterList( UserStore.GetSchoolList )
       }else{
           this.handleUpData()
       }
    }

    handleShowInput(){
        this.InputShow = true
        this.input.focus()
    }

    handleShowClose(){
        let reg = / /g
        this.searchVal = this.searchVal.replace(reg,"")
        if( this.searchVal.length <= 0 ){ this.InputShow = false }
    }

   @Watch("searchVal")
   handleChangeVal(newval:any){
       if( this.SearchBool ){
           if( newval.length <= 0 ) {
               this.SearchBool = false
               this.handleFilterList( UserStore.GetSchoolList )
           }
       }
   }

    handleUpSerchBtn(){
        let reg = / /g
        this.searchVal = this.searchVal.replace(reg,"")
        if( this.searchVal.length ){
            this.loadingShow = true
            this.handleUpData( this.searchVal )
            this.SearchBool = true
        }
    }

    handleUpDown(data:any){
        if( this.NotUpDown )return;
        this.UpData.pageNo += 1
        this.loadingShow = true
        this.handleUpData()
    }

   handleUpData(name?:string){
       if( name ){
           this.UpData.name = name
           this.UpData.pageNo = 1
       }
       GetSchool({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.loadingShow = false
           if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.NotUpDown = true
               }
               this.handleFilterList( res.data )
               if( !name ){
                   UserStore.SetSchoolList( res.data )
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleFilterList( data:SchoolTypes ){
       let newJsonData:{ [propsData:string]:any } = {}
       if ( Array.isArray(data) ) {
           data.forEach( (item,index)=>{
               let FirstCode = item.code.slice(0,1)
               let Str:string = FirstCode.toUpperCase()
               let keyJson = newJsonData[Str]
               if ( Boolean(keyJson) ){
                   newJsonData[Str].push(item)
               }else{
                   newJsonData[Str] = [ item ]
               }
           } )
       }
       let KeyArr = Object.keys( newJsonData )
       KeyArr = KeyArr.sort()
       this.data = newJsonData
       this.KeyList = KeyArr
   }

   handleBandSchool(data:SchoolTypes){
       this.loadingShow = true
       new Promise( (resolve,reject)=>{
           BandSchool({
               userId:this.userId,
               token:this.token
           },{
               school:data.code as string
           }).then( (res:any)=>{
               if( res.message.code === "200" ){
                   resolve( true )
               }else{
                   reject( res )
               }
           } )
       } ).then( (res:any)=>{
           GetUserData({
               userId:this.userId,
               token:this.token
           }).then( (res:any)=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Stroage.SetData_ && Stroage.SetData_( "UserData",JSON.stringify( res.data ) )
                   IndexStore.SetLoading( true )
                   if( this.BackBool ){
                       Toast({
                           message:"信息完善成功",
                           icon:"none",
                           className:"ToastClassName"
                       })
                       let time = setTimeout(()=>{
                           this.$router.replace({
                               name:"home"
                           })
                           clearTimeout( time )
                       },500)
                   }else{
                       Dialog({
                           title:"欢迎使用三易校园",
                           message:"前期软件不太完善，请多多谅解，如需要帮助请及时拨打三易客服电话 0371-5880688",
                           className:"HideClassName",
                           overlayClass:"MaskHideClassName"
                       }).then( (res:any)=>{
                           this.$router.replace({
                               name:"home"
                           })
                       } ).catch( (cat:any)=>cat )
                   }
               }else{
                   this.handleError( res.message.msg )
               }
           } )
       } ).catch( (cat:any)=>{
           this.loadingShow = false
           this.handleError( cat.message.msg )
       } )

   }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
